<template>
    <div>
        <div class="text-xs-right" >
            <v-btn class="white--text elevation-6" small round color="teal darken-2" to="/savings/withdrawals/history">
                <span> My Withdrawal History</span>
            </v-btn>
        </div>
        <h2 class="elevation-4 primary white--text font-weight-bold subheading py-2 text-xs-center">REQUEST A WITHDRAWAL</h2>

        <div class="elevation-4 white lighten-4">
            <p class="dark--text subheading pa-2 ma-0"><b>Principal Balance:</b> <span> {{campaign.saved_amount}} FCFA</span></p>
            <v-divider></v-divider>
        </div>
        <v-card color="elevation-4 white pa-1" style="" >
            <p class="dark--text caption pt-2 ma-0 text-xs-center">The withdrawal will be validated in at most 24 hrs and the money sent to your number below</p>
            <v-layout row>
                <v-flex xs12 md10 offset-md1>            
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-money-bill fa-2x mt-2"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-text-field
                                class="pa-0 mt-2"
                                id="withdraw_amount" ref="withdraw_amount" v-model="withdraw_amount" v-on:input="getWithdrawalFee()"
                                label="Input amount (F CFA)"
                                :rules="[v => !!v || 'Your requested amount is required']"
                                required
                                color="teal"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); withdraw_amount_help = true; getWithdrawalFieldHelp()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-money-bill fa-2x mt-0"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-text-field
                                class="pa-0 mt-0"
                                id="withdraw_fee" ref="withdraw_fee" v-model="withdraw_fee"
                                label="Withdrawal fees (F CFA)" 
                                disabled
                                color="teal"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); withdraw_fee_help = true; getWithdrawalFieldHelp()" flat fab small outline color="black" class="my-3" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-phone fa-2x mt-0"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <input @click="clearPhoneField()" id="phone" required type="tel" value="Enter Phone Number" v-model="phone_number" ref="phone_number" name="phone" class="py-2 my-2" style="width: 100%!important; border-bottom: 1px grey solid" />
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); withdraw_phone_help = true; getWithdrawalFieldHelp()" flat fab small outline color="black" class="my-3" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                        <v-flex xs12 class="pt-3">
                            <h3><i class="teal--text fas fa-wrench"> </i> Select Transactor Operator</h3>                       
                        </v-flex>
                        <v-flex xs6 sm3 md4 offset-sm1 class="pt-2" @click="selectMoMo()">
                            <v-img
                                :src="require('@/assets/mtn-mobile-money-logo.png')"
                                style="cursor: pointer" contain width="100"
                            ></v-img>
                            <v-checkbox v-model="momo_choice" :checked="momo_choice" label="MTN MoMo" hide-details class="ma-1" id="selected_momo">
                            </v-checkbox>
                        </v-flex>
                        <v-flex xs6 sm3 class="pt-2" @click="selectOM()">
                            <v-img
                                :src="require('@/assets/om.png')"
                                style="cursor: pointer" contain width="70"
                            ></v-img>
                            <v-checkbox v-model="om_choice" :checked="om_choice" label="OM" hide-details class="ma-1" id="selected_om">
                            </v-checkbox>
                        </v-flex>
                        <v-flex xs12 sm4 class="pt-4 mt-3" @click="selectPettyCash()">
                            <v-img
                                :src="require('@/assets/logo-2.png')"
                                style="cursor: pointer" contain width="200"
                            ></v-img>
                            <v-checkbox v-model="pettycash_choice" :checked="pettycash_choice" label="Petty Cash" hide-details class="ma-1" id="selected_petty_cash">
                            </v-checkbox>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
            <div class="text-xs-center">
                <v-btn class="white--text elevation-6" small round color="teal darken-2" @click="requestWithdrawal()">
                    <span> Submit</span>
                </v-btn>
            </div>
        </v-card>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {

        data() {
            return {
                momo_choice: false,
                om_choice: false,
                pettycash_choice: false,

                withdraw_amount_help: false,
                withdraw_phone_help: false,
                withdraw_operator_help: false,
                withdraw_fee_help: "",
                transaction_operator: "",
                phone_number: "Enter Phone Number",
                withdraw_amount: "",
                withdraw_fee: "",
                payment_ref: "",
                campaign: [],
                operators: [],
                phoneInput: [],
            }
        },
        mounted(){
            // executes these after the page has been mounted
            this.getCampaignDetail();
            this.getOperators();
            this.getCampaignPaymentRef();
            this.initialiseIntel()
            document.title = "PettyCash | Request Withdrawal"
            this.$store.commit('setCloseURL', "/savings")
        },

        methods:{
            selectMoMo(){
                this.momo_choice = true
                this.om_choice = false
                this.pettycash_choice = false
                this.transaction_operator = "MTN Mobile Money"
            },
            selectOM(){
                this. momo_choice = false
                this.om_choice = true
                this.pettycash_choice = false
                this.transaction_operator = "Orange Money"
            },
            selectPettyCash(){
                this.momo_choice = false
                this.om_choice = false
                this.pettycash_choice = true
                this.transaction_operator = "Top Up Station"

                this.$store.commit('setSnackBarMessage', "This option is currently unavaible")
                this.$store.commit('setSnackBarColor', "teal darken-4")
                this.$store.commit('activateSnackBar', true)
                this.pettycash_choice = false
            },

            clearPhoneField(){
                let phone_number = this.phoneInput.getNumber()
                console.log(phone_number)
                if(phone_number === "Enter Phone Number"){
                    this.phone_number = ""
                }

            },
            initialiseIntel(){
                function getIp(callback) {
                    fetch('https://ipinfo.io/json?token=fa5e74d3e65812', { headers: { 'Accept': 'application/json' }})
                    .then((resp) => resp.json())
                    .catch(() => {
                        return {
                            country: 'cm',
                        };
                    })
                    .then((resp) => callback(resp.country));
                }

                const phoneInputField = document.querySelector("#phone");
                this.phoneInput = window.intlTelInput(phoneInputField, {
                    initialCountry: "cm",
                    geoIpLookup: getIp,
                    preferredCountries: ["cm", "us", "uk", "ci", "cg", "cf", "td", "ng", "gq", "ga"],
                    utilsScript:
                    "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
                });
            },

            getWithdrawalFee(){
                console.log(Number(this.withdraw_amount))
                if (Number(this.withdraw_amount) < 1000 || Number(this.withdraw_amount)%1000 !== 0){
                    this.$store.commit('setSnackBarMessage', "Amount must be multiple of 1000")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
                else if (Number(this.withdraw_amount) > Number(this.campaign.saved_amount)){
                    this.$store.commit('setSnackBarMessage', "You can't withdraw more than your account balance")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
                else{
                    axios
                    .get('/api/v1/savings/get/user/requested/withdrawal/fee/'+this.withdraw_amount)
                    .then(response => {
                        this.withdraw_fee = response.data  // get the data and fill into campaigns
                        console.log(this.withdraw_fee)
                    })
                    .catch(error => {
                        console.log(error.response)
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })

                }
            },

            async getCampaignDetail(){
                // getting the principal campaign
                this.$store.commit('setIsLoading', true)

                await axios
                    .get('/api/v1/savings/get/user/campaign/detail/0/')
                    .then(response => {
                        this.campaign = response.data  // get the data and fill into campaigns
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
            
            async getOperators(){
                this.$store.commit('setIsLoading', true)

                await axios
                    .get('/api/v1/home/get/operators/info/')
                    .then(response => {
                        this.operators = response.data  // get the data and fill into operators
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

            async getCampaignPaymentRef(){
                this.$store.commit('setIsLoading', true)
                console.log(this.campaign)
                
                await axios
                    .get('/api/v1/savings/create/user/withdrawal/campaign/payment_ref/')
                    .then(response => {
                        this.payment_ref = response.data
                        console.log(this.payment_ref, response.data)  
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
            
            getWithdrawalFieldHelp(){
                if (this.withdraw_amount_help){
                    this.withdraw_amount_help = false
                    this.$store.commit('setDialogTitle', "Withdrawal Amount")
                    this.$store.commit('setDialogMessage', "Please the amount you want to withdraw.")
                    this.$store.commit('activateDialog', true)
                }else if (this.withdraw_phone_help){
                    this.withdraw_date_help = false
                    this.$store.commit('setDialogTitle', "Withdrawal Contact")
                    this.$store.commit('setDialogMessage', "Please input the contact you want the money sent to (MTN Momo, Orange Money, Yup, Express Union, Western Union).")
                    this.$store.commit('activateDialog', true)
                }else if (this.withdraw_fee_help){
                    this.withdraw_fee_help = false
                    this.$store.commit('setDialogTitle', "Transaction Fee")
                    this.$store.commit('setDialogMessage', "This displays the fees/charges you will have to pay for the transaction.")
                    this.$store.commit('activateDialog', true)
                }else if (this.withdraw_operator_help){
                    this.withdraw_operator_help = false
                    this.$store.commit('setDialogTitle', "Transaction Operator")
                    this.$store.commit('setDialogMessage', "Choose the operator through which the transaction should be made, either MTN, Orange, Express Union, Western Union, Bank transactions etc.")
                    this.$store.commit('activateDialog', true)
                }
            },

            async requestWithdrawal(){
                if(this.withdraw_amount === "" || String(Number(this.withdraw_amount)) === "NaN"){
                    if(this.withdraw_amount === ""){
                        this.$store.commit('setSnackBarMessage', "Please input the amount you want to withdraw")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                        this.$refs.withdraw_amount.focus()  // this causes a an auto fucos to the element
                    }
                    else{
                        this.$store.commit('setSnackBarMessage', "Please input only numbers for the amount")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                        this.$refs.withdraw_amount.focus()  // this causes a an auto fucos to the element
                    }
                }
                else if(!this.phoneInput.isValidNumber()){
                    this.$store.commit('setSnackBarMessage', "Phone number is invalid. Please input a valid number")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.phone_number.focus()  // this causes a an auto fucos to the element
                }
                else if(this.transaction_operator === ""){
                    this.$store.commit('setSnackBarMessage', "Please select the operator through which you sent the money.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.transaction_operator.focus()  // this causes a an auto fucos to the element
                }
                else if(this.payment_ref === ""){
                    this.$store.commit('setSnackBarMessage', "Please an error occured with transaction, please try again later.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    window.location.reload()  // to reload and get another payment reference. this is for secuirity reasons
                }
                else if (Number(this.withdraw_amount) < 1000 || Number(this.withdraw_amount)%1000 !== 0){
                    this.$store.commit('setSnackBarMessage', "Withdrawal amount must be in 1000s bills")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.withdraw_amount.focus()  // this causes a an auto fucos to the element
                }
                else if ((Number(this.withdraw_amount) + Number(this.withdraw_fee) )> Number(this.campaign.saved_amount)){
                    this.$store.commit('setSnackBarMessage', "You can't withdraw more than your account balance")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.withdraw_amount.focus()  // this causes a an auto fucos to the element
                }
                else {
                    this.$store.commit('setIsLoading', true)
                    let formData = new FormData()
                    formData.append('withdraw_amount', this.withdraw_amount)
                    formData.append('phone_number', this.phoneInput.getNumber())
                    formData.append('transaction_operator', this.transaction_operator)
                    formData.append('campaign_id', this.campaign.id)
                    formData.append('payment_ref', this.payment_ref)
                    formData.append('feature', "Savings")
                    console.log(formData)
    
                    await axios
                        .post('/api/v1/savings/request/withdrawal/', formData)
                        .then(response => {
                            console.log(response.data)
                            this.$store.commit('setSnackBarMessage', response.data[0].text)
                            this.$store.commit('setSnackBarColor', response.data[0].color)
                            this.$store.commit('activateSnackBar', true)
                            this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                            var navigation = this.$router
                            var store = this.$store  // timers are noted for this
                            if (response.data[0].valid){
                                setTimeout(function(){
                                    // window.location.reload()  // to reload and get another payment reference. this is for secuirity reasons
                                    navigation.push('/savings/withdrawals/history')  // redirect back to campaign
                                    store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 4 seconds and remember too reset after 
                                }, 8000)
                            }
                        })
                        .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                        })
                    this.$store.commit('setIsLoading', false)
                }

            },

        },       

    }
</script>
